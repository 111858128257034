.px-btn {
  padding: 0 25px;
  line-height: 42px;
  position: relative;
  display: inline-block;
  background: none;
  border: 2px solid transparent;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none !important;
  transition: 0.4s;
  letter-spacing: 0.3px;
  cursor: pointer !important;
  &.btn-outline {
    border: 2px solid darken($px-theme, 10%);
    &:hover {
      background: darken($px-theme, 10%);
      color: #fff;
    }
  }
  &.px-btn-theme {
    background: darken($px-theme, 10%);
    border: 2px solid darken($px-theme, 10%);
    color: #fff;
    &:hover {
      background: $px-white;
      color: $px-theme;
    }
  }
  &.px-btn-white {
    background: $px-white;
    border-color: $px-white;
    color: darken($px-theme, 10%);
    .theme-light & {
      color: $px-white;
      background: darken($px-theme, 10%);
      border-color: darken($px-theme, 10%);
    }
    &:hover {
      background: transparent;
      color: $px-white;
      transform: translateY(0px);
      .theme-light & {
        color: darken($px-theme, 10%);
      }
    }
  }
}
.btn-wrapper {
  margin-top: 20px;
}
